import SlideArrowIcon from 'components/icons/SlideArrow';
import { Button } from './Arrow.styles';

const Arrow = ({ direction, onClick = () => {}, isDisabled = false }) => (
  <Button direction={direction} onClick={onClick} disabled={isDisabled}>
    <SlideArrowIcon height='14px' />
  </Button>
);

export default Arrow;
