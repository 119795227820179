const PhoneEmptyIcon = (props = {}) => (
  <svg
    enableBackground='new 0 0 512 512'
    version='1.1'
    viewBox='0 0 512 512'
    space='preserve'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      className='st0'
      d='m512 415c-0.8-20.5-13.4-40.3-38.4-60.4-33.5-27-59.5-37.9-81.5-34.4-19.9 3.2-34.2 17.6-49.2 32.9l-5.4 5.4c-5.6 5.6-20.1 1.5-39.8-11.3-19.2-12.5-43.3-33.1-71.5-61.4-37-37-60.6-66.4-70.3-87.4-7.7-16.7-3.7-22.5-2.4-23.9l5.4-5.3c15.3-15.1 29.8-29.3 33-49.3 3.5-22.1-7.4-48-34.4-81.6-20.2-24.9-40-37.5-60.5-38.3-29.9-1.1-51.9 22.1-71.4 42.5l-0.3 0.3c-3.1 3.3-6 6.4-8.9 9.3-21.7 21.7-21.9 68.5-0.4 125.3 23.3 61.8 69.3 128.9 129.5 189.1s127.3 106.2 189.1 129.4c27.8 10.5 53.9 16 75.5 16 21.9 0 39.1-5.7 49.8-16.4 2.6-2.6 5.4-5.2 8.3-8l1.3-1.2c20.4-19.4 43.7-41.5 42.5-71.3zm-374.1-256.1c-12.3 12.3-12.1 29.9 0.4 53.8 12.1 23.1 35.7 52.1 72.3 88.6 29.3 29.3 54.6 50.9 75.1 64.3 30.5 19.8 53.1 22.6 67.4 8.4l5.5-5.5c27.8-28.1 43-43.6 101.3 3.2 19.5 15.6 29.6 30.5 30.2 44.1 0.8 19.8-18.6 38.2-35.7 54.5-3.6 3.4-6.9 6.5-10 9.6-15 15-55 13.1-102-4.6-59-22.1-123.3-66.3-181.4-124.3-58-58-102.2-122.4-124.5-181.3-17.7-47-19.5-87.1-4.6-102 3.1-3.1 6.1-6.3 9.3-9.7l0.3-0.3c16-16.8 34-35.7 53.3-35.7h0.1 1.1c13.6 0.6 28.5 10.7 44.2 30.2 46.9 58.3 31.3 73.6 3.1 101.4l-5.4 5.3z'
    />
  </svg>
);
export default PhoneEmptyIcon;
