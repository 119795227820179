const InvestmentMarkerIcon = (props = {}) => (
  <svg
    enableBackground='new 0 0 512 512'
    version='1.1'
    viewBox='0 0 512 512'
    space='preserve'
    xmlns='http://www.w3.org/2000/svg'
    width='40'
    height='40'
    {...props}
  >
    <path
      className='st0-investment'
      d='m427.1 254.6c8.5-21.2 13.2-44.3 13.2-68.5 0-101.3-82.1-183.4-183.4-183.4s-183.4 82-183.4 183.4c0 24.2 4.7 47.4 13.2 68.5l2.5 5.9c8.5 19.1 20.2 36.6 34.4 51.6l133.3 200 133.3-200c14.2-15 25.9-32.4 34.4-51.6l2.5-5.9z'
      fill='#454851'
    />
    <path
      className='st1-investment'
      d='m256.9 42.1c-79.3 0-143.9 64.6-143.9 143.9s64.6 144 143.9 144 143.9-64.6 143.9-143.9-64.5-144-143.9-144zm0 270.1c-69.6 0-126.2-56.6-126.2-126.2s56.7-126.1 126.2-126.1 126.2 56.6 126.2 126.2-56.6 126.1-126.2 126.1z'
      fill='#FFFFFF'
    />
    <circle
      className='st1-investment'
      cx='256.9'
      cy='186.1'
      r='14.8'
      fill='#FFFFFF'
    />
  </svg>
);
export default InvestmentMarkerIcon;
