const POIPostIcon = (props = {}) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 373.574 373.574'
    width='18'
    {...props}
  >
    <g>
      <g>
        <path
          d='M314.327,64.559H58.807c-14.497-0.721-28.746,3.953-40,13.12l14.48,9.36l109.92,71.2c1.074,0.375,2.056,0.975,2.88,1.76
			l40,26l153.68-98.88l14.56-9.44C342.903,68.829,328.774,64.194,314.327,64.559z'
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d='M22.247,98.799l-13.52-8.8c-5.771,10.076-8.753,21.508-8.64,33.12l-0.08,127.12c-0.203,13.555,3.947,26.818,11.84,37.84
			l11.36-11.76l104.72-108.8L22.247,98.799z'
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d='M338.807,287.119l-106.8-110.72l-40.8,26.4l-1.36,0.64h-0.64c-0.664,0.084-1.336,0.084-2,0c-0.664,0.084-1.336,0.084-2,0
			h-0.64l-1.92-0.64l-40.48-26.24l-107.36,110.88l-11.52,11.92c10.566,6.796,22.967,10.175,35.52,9.68h256
			c12.504,0.08,24.762-3.478,35.28-10.24L338.807,287.119z'
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d='M373.527,250.239v-126.88c0.357-11.66-2.552-23.187-8.4-33.28l-13.6,8.72l-105.6,68.4l104.64,108.88l11.44,11.92
			C370.002,277.075,374.063,263.766,373.527,250.239z'
        />
      </g>
    </g>
  </svg>
);
export default POIPostIcon;
