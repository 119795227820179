import MenuLink from '../MenuLink';

import { Wrapper } from './Menu.styles';

const Menu = ({ menuItems = [], nested = false }) => (
  <Wrapper>
    {menuItems
      .filter((el) => !el.hidden)
      .map((el, key) => (
        <MenuLink key={key} item={el} nested={nested}>
          {el.value}
        </MenuLink>
      ))}
  </Wrapper>
);

export default Menu;
