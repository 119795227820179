import styled, { css } from 'styled-components';

const Tooltip = styled.div`
  position: absolute;
  visibility: hidden;
  z-index: -1;
  background-color: ${({ theme }) => theme.secondary13Color};
  padding: 0 12px;
  white-space: nowrap;
  color: ${({ theme }) => theme.primary2Color};
  font-size: 8px;
  text-transform: uppercase;
  font-weight: 800;
  line-height: 1.8;
  letter-spacing: 0.4px;

  left: 65%;
  top: 35%;
  transform: translateY(-50%);

  ${({ checked }) =>
    checked &&
    css`
      visibility: visible;
      background-color: ${({ theme }) => theme.primary4Color};
    `}

  ${({ $hideTooltip, isOldMarkerStyle }) =>
    $hideTooltip &&
    isOldMarkerStyle &&
    css`
      display: none;
    `}
`;

const NewInvestmentTooltipStyling = css`
  border-radius: 0 2px 2px 0;
  font-size: 10px;
  line-height: 12px;
  font-weight: 600;
  padding: 4px 10px;

  @media (min-width: ${(props) => props.theme.breakpoints.sm}) {
    &:hover {
      background-color: ${({ theme }) => theme.primary4Color};
    }
  }
`;

const InvestmentTooltip = styled(Tooltip)`
  ${({ $isOldMarkerStyle }) =>
    !$isOldMarkerStyle && NewInvestmentTooltipStyling}

  ${({ disabled, theme }) =>
    disabled &&
    css`
      background-color: ${theme.secondary13Color};
    `}

  ${({ $highlighted }) =>
    $highlighted &&
    css`
      background-color: ${(props) => props.theme.primary4Color};
    `}

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    display: none;
  }
`;

const NewOfficeTooltipStyling = css`
  background-color: ${({ theme }) => theme.secondary13Color};
  border-radius: 0 2px 2px 0;
  font-size: 10px;
  line-height: 12px;
  font-weight: 600;
  padding: 4px 10px;

  @media (min-width: ${(props) => props.theme.breakpoints.sm}) {
    &:hover {
      background-color: ${({ theme }) => theme.primary4Color};
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    display: none;
  }
`;

const OfficeTooltip = styled(InvestmentTooltip)`
  background-color: ${({ theme }) => theme.secondary13Color};

  ${({ $isOldMarkerStyle }) => !$isOldMarkerStyle && NewOfficeTooltipStyling}

  ${({ disabled, theme }) =>
    disabled &&
    css`
      background-color: ${theme.secondary13Color};
    `}
`;

const PoiTooltip = styled.div`
  position: absolute;
  z-index: 5;
  display: inline-block;
  visibility: hidden;
  top: -15px;
  left: 50%;
  background-color: ${({ theme }) => theme.backgroundColor};
  padding: 10px 15px;
  border: 2px solid ${({ theme }) => theme.primary3Color};
  border-radius: 10px;
  white-space: nowrap;
  font-size: 16px;

  ${({ hideTooltip }) =>
    hideTooltip &&
    css`
      display: none;
    `}

  &::after,
  &::before {
    top: 100%;
    left: 50%;
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  &::after {
    border-color: rgba(136, 183, 213, 0);
    border-top-color: ${({ theme }) => theme.backgroundColor};
    border-width: 10px;
    margin-left: -10px;
  }

  &::before {
    border-color: rgba(194, 225, 245, 0);
    border-top-color: ${({ theme }) => theme.primary3Color};
    border-width: 13px;
    margin-left: -13px;
  }
`;

const NewMarkerWrapperStyling = css`
  div {
    visibility: visible;
  }
`;

const OldMarkerWrapperStyling = css`
  &:hover {
    div {
      visibility: visible;
    }
  }
`;

const MarkerWrapper = styled.div`
  position: relative;
  display: inline-block;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  width: 40px;

  svg {
    height: auto;
    width: 100%;
  }

  ${({ isOldMarkerStyle, $isTooltipOnHover }) =>
    isOldMarkerStyle || $isTooltipOnHover
      ? OldMarkerWrapperStyling
      : NewMarkerWrapperStyling}
`;

const NewInvestmentMarkerWrapperStyling = css`
  @media (min-width: ${(props) => props.theme.breakpoints.sm}) {
    &:hover {
      z-index: 5;

      .st0-investment {
        fill: ${(props) => props.theme.primary4Color};
      }

      & > div {
        background-color: ${(props) => props.theme.primary4Color};
      }
    }
  }
`;

const InvestmentMarkerWrapper = styled(MarkerWrapper)`
  width: 40px;

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    width: ${({ $isModalOpen }) => ($isModalOpen ? '40px' : '25px')};
  }

  &:hover {
    z-index: 5;
  }

  ${({ $isOldMarkerStyle }) =>
    !$isOldMarkerStyle && NewInvestmentMarkerWrapperStyling}

  ${({ checked, areMarkersRed }) =>
    (checked || areMarkersRed) &&
    css`
      .st0-investment {
        fill: ${(props) => props.theme.primary4Color};
      }
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      .st0-investment {
        fill: ${(props) => props.theme.secondary13Color};
      }
    `}

  ${({ $highlighted }) =>
    $highlighted &&
    css`
      .st0-investment {
        fill: ${(props) => props.theme.primary4Color};
      }
    `}

  ${({ $isModalOpen, checked }) =>
    $isModalOpen &&
    !checked &&
    css`
      .st0-investment {
        fill: ${(props) => props.theme.secondary13Color};
      }
    `}

  ${({ $isModalOpen, checked }) =>
    $isModalOpen &&
    checked &&
    css`
      .st0-investment {
        fill: ${(props) => props.theme.primary4Color};
        width: 40px;
      }

      @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
        width: 40px;
      }
    `}

  ${({ $isModalOpen, $activeMarkedEntityData }) =>
    $isModalOpen &&
    !$activeMarkedEntityData &&
    css`
      .st0-investment {
        fill: ${(props) => props.theme.secondary13Color};
        width: 40px;
      }

      @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
        width: 40px;
      }
    `}
`;

const NewOfficeMarkerWrapperStyling = css`
  width: 40px;

  @media (min-width: ${(props) => props.theme.breakpoints.sm}) {
    &:hover {
      z-index: 5;
      .st0-investment {
        fill: ${(props) => props.theme.primary4Color};
      }

      & > div {
        background-color: ${(props) => props.theme.primary4Color};
      }
    }
  }
`;

const OfficeMarkerWrapper = styled(MarkerWrapper)`
  width: 50px;

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    width: ${({ $isModalOpen }) => ($isModalOpen ? '40px' : '25px')};
  }

  &:hover {
    z-index: 5;
  }

  ${({ $isOldMarkerStyle }) =>
    !$isOldMarkerStyle && NewOfficeMarkerWrapperStyling}

  ${({ disabled }) =>
    disabled &&
    css`
      .st0-office {
        fill: ${(props) => props.theme.secondary13Color};
      }
    `}

  ${({ $isModalOpen, checked }) =>
    $isModalOpen &&
    checked &&
    css`
      .st0-office {
        fill: ${(props) => props.theme.primary4Color};
        width: 40px;
      }

      @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
        width: 40px;
      }
    `}
`;

export {
  PoiTooltip,
  InvestmentTooltip,
  OfficeTooltip,
  InvestmentMarkerWrapper,
  OfficeMarkerWrapper,
  MarkerWrapper,
};
