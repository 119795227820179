import Link from 'components/ui/Link';
import {
  FavouritesContainer,
  FavouritesCountContainer,
} from './Favourites.styles';
import HeartIcon from '../../../icons/Heart';

const Favourites = ({ favouritesCount, mobileVersion = false }) => (
  <FavouritesContainer mobileVersion={mobileVersion}>
    <Link href='/ulubione'>
      <HeartIcon />
      <FavouritesCountContainer mobileVersion={mobileVersion}>
        {favouritesCount}
      </FavouritesCountContainer>
    </Link>
  </FavouritesContainer>
);

export default Favourites;
