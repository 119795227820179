import isString from 'lodash/isString';

import SectionHeader from 'components/ui/SectionHeader';
import ContactUsEntry from './components/ContactUsEntry';

import { ItemsWrapper, ItemWrapper, Wrapper } from './ContactUsBlock.styles';

const ContactUsBlock = ({
  items = [],
  showIcons = false,
  title,
  transparentBackground = false,
  alignCenter = false,
  rowLayout = false,
}) => (
  <Wrapper
    $transparentBackground={transparentBackground}
    $alignCenter={alignCenter}
  >
    {isString(title) ? (
      <SectionHeader
        longBox
        uppercase={false}
        boxPosition='bottom'
        size='large'
        lightText={title}
        alignTextLeft
      />
    ) : (
      title
    )}
    <ItemsWrapper $rowLayout={rowLayout}>
      {items
        .filter(({ email, phone, open }) => email || phone || open)
        .map((el, key) => (
          <ItemWrapper key={key}>
            <ContactUsEntry {...el} showIcons={showIcons} />
          </ItemWrapper>
        ))}
    </ItemsWrapper>
  </Wrapper>
);

export default ContactUsBlock;
