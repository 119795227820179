import Slider from './components/Slider';
import { Wrapper, Container, Title, Row } from './RangeSlider.styles';

const RangeSlider = ({
  handleChange = () => {},
  firstDisplayValue = null,
  secondDisplayValue = null,
  title = null,
  formatDisplay = null,
  sliderMin,
  sliderMax,
  isWithoutExtraPadding = false,
  isDisabled = false,
}) => (
  <Wrapper disabled={isDisabled}>
    <Row>
      <Title>{title}</Title>
    </Row>
    <Container>
      <Slider
        handleChange={handleChange}
        minValue={sliderMin}
        maxValue={sliderMax}
        minSelected={firstDisplayValue}
        maxSelected={secondDisplayValue}
        isWithoutExtraPadding={isWithoutExtraPadding}
        formatDisplay={formatDisplay}
      />
    </Container>
  </Wrapper>
);

export default RangeSlider;
