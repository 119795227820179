const POIGymIcon = (props = {}) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='14'
    height='19'
    viewBox='0 0 14 19'
    {...props}
  >
    <path
      d='M724,1730.06a6.97,6.97,0,1,0,7,6.97A6.995,6.995,0,0,0,724,1730.06Zm-3.285,10.43a0.347,0.347,0,0,1-.216.08,0.306,0.306,0,0,1-.233-0.1,5.025,5.025,0,0,1-1.312-2.76,0.316,0.316,0,0,1,.273-0.36,0.322,0.322,0,0,1,.358.27,4.374,4.374,0,0,0,1.147,2.42A0.318,0.318,0,0,1,720.715,1740.49Zm0.609,0.81a0.317,0.317,0,0,1-.318-0.32,0.318,0.318,0,1,1,.636,0A0.317,0.317,0,0,1,721.324,1741.3Zm2.676,0.8a4.954,4.954,0,0,1-1.828-.34,0.316,0.316,0,0,1-.182-0.41,0.323,0.323,0,0,1,.411-0.18,4.36,4.36,0,0,0,1.6.3A0.315,0.315,0,1,1,724,1742.1Zm-5.19-10.64a7.609,7.609,0,0,1,2.432-1.52c-0.052-.75-0.086-1.47-0.1-2.15a16.171,16.171,0,0,1,2.859-.27,16.617,16.617,0,0,1,2.856.27c-0.015.71-.048,1.42-0.1,2.15a7.624,7.624,0,0,1,2.433,1.52c0.141-1.57.217-3.13,0.216-4.64a1.328,1.328,0,0,0-1.1-1.35,19.547,19.547,0,0,0-8.611,0,1.325,1.325,0,0,0-1.109,1.33C718.593,1728.32,718.668,1729.88,718.81,1731.46Z'
      transform='translate(-717 -1725)'
    />
  </svg>
);
export default POIGymIcon;
