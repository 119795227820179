import { FormattedMessage } from 'react-intl';

import Magnifier from 'components/icons/Magnifier';
import Mail from 'components/icons/Mail';
import Phone from 'components/icons/PhoneEmpty';
import Link from 'components/ui/Link';
import ContactIcon from './components/ContactIcon';

import { Wrapper, ElementWrapper } from './ContactIcons.styles';
import messages from './ContactIcons.messages';

const ContactIcons = ({ theme = null, mail = null, phone = null }) => (
  <Wrapper>
    <ElementWrapper>
      <Link href={{ pathname: '/wyszukiwarka' }}>
        <ContactIcon
          theme={theme}
          icon={<Magnifier />}
          description={<FormattedMessage {...messages.search} />}
        />
      </Link>
    </ElementWrapper>
    {mail ? (
      <ElementWrapper>
        <Link href='/kontakt#form'>
          <ContactIcon
            icon={<Mail />}
            description={<FormattedMessage {...messages.mail} />}
          />
        </Link>
      </ElementWrapper>
    ) : null}
    {phone ? (
      <ElementWrapper>
        <ContactIcon
          href={`tel:${phone}`}
          icon={<Phone width={31} height={31} />}
          description={
            <FormattedMessage {...messages.phone} values={{ phone }} />
          }
        />
      </ElementWrapper>
    ) : null}
  </Wrapper>
);

export default ContactIcons;
