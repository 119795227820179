const POISchoolIcon = (props = {}) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='18.969'
    height='16'
    viewBox='0 0 18.969 16'
    {...props}
  >
    <path
      d='M724.719,1666.79a2.418,2.418,0,0,1-2.439,0l-5.521-3.5s-0.5-.31-0.5.4v3.49c0,1.84,3.24,3.82,7.238,3.82s7.239-1.98,7.239-3.82v-3.68c0-.58-0.354-0.3-0.354-0.3Zm7.962-6.27a0.536,0.536,0,0,0,0-.98l-8.41-4.34a1.571,1.571,0,0,0-1.543,0l-8.411,4.34a0.537,0.537,0,0,0,0,.98l8.411,5.32a1.571,1.571,0,0,0,1.543,0m8.063,2.56v-5.87s0-.28-0.157-0.19a4.162,4.162,0,0,0-.554.35,0.525,0.525,0,0,0-.1.38v5.33a0.139,0.139,0,0,1-.093.13,1.037,1.037,0,0,0-.486.88,0.984,0.984,0,1,0,1.968,0,1.023,1.023,0,0,0-.49-0.88A0.151,0.151,0,0,1,732.334,1668.4Z'
      transform='translate(-714.031 -1655)'
    />
  </svg>
);
export default POISchoolIcon;
