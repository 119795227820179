import { withTheme } from 'styled-components';

import FullArrow from 'components/icons/FullArrow';
import { Button } from './GoToTop.styles';

const GoToTop = ({ onClick = () => {}, theme }) => (
  <Button onClick={onClick}>
    <FullArrow fill={theme.primary2Color} />
  </Button>
);

export default withTheme(GoToTop);
