import styled, { css } from 'styled-components';

const Button = styled.button`
  pointer-events: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 0;
  background-color: ${(props) => props.theme.white};
  color: ${(props) => props.theme.primary3Color};
  font-weight: 700;
  transition: background-color ${(props) => props.theme.animationDuration};

  ${(props) => props.direction === 'left' &&
    css`
      transform: scaleX(1);
      border-radius: 10rem 0 0 10rem;
      height: 38px;
      width: 46px;
      padding-left: 8px;

      &:hover {
        opacity: 0.95;
      }
    `}

  ${(props) => props.direction === 'right' &&
    css`
      transform: scaleX(-1);
      border-radius: 10rem 0 0 10rem;
      height: 38px;
      width: 46px;
      margin-left: -1px;

      &:hover {
        opacity: 0.95;
      }
    `}

  ${(props) => props.disabled &&
    css`
      cursor: default;

      & > svg {
        opacity: 0.2;
      }

      &:hover {
        opacity: 1;
      }
    `}

  &:focus {
    outline: 0;
  }
`;

export { Button };
