import styled, { css } from 'styled-components';
import Link from 'components/ui/Link';
import config from 'config';
import { ContainerMain } from 'utils/styles';
import NextImage from 'next/image';

const Wrapper = styled.div`
  position: relative;
  cursor: default;
  height: 100%;
`;

const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: ${config.sliderWidth.value}${config.sliderWidth.unit};
  margin: 0 auto;

  ${(props) =>
    !props.$isImageLoaded &&
    css`
      width: 100%;
      aspect-ratio: ${props.imageDimensions};
    `}

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    width: 100%;
  }
`;

const PromotionWrapper = styled(Link)`
  width: 42%;
  background: ${(props) => props.theme.primary4Color};
  height: 155px;
  position: absolute;
  right: 0;
  bottom: -21px;
  padding: 0 35px;

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    height: auto;
    padding: 0 0 0 15px;
    width: 68%;
    bottom: 0;
  }
`;

const PromotionText = styled.p`
  color: ${(props) => props.theme.primary2Color};
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 1.2px;

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    font-size: 11px;
  }
`;

const Image = styled(NextImage)`
  display: block;
  width: ${({ $isHalfWidth }) => ($isHalfWidth ? '50%' : '100%')};
  max-height: 100%;
  ${({ $isHalfWidth }) =>
    $isHalfWidth &&
    css`
      margin: 0 auto;
    `}
  ${({ $withPointer }) =>
    $withPointer &&
    css`
      cursor: pointer;
    `}
`;

const InfoOverlayWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`;

const InfoOverlayContainer = styled(ContainerMain)`
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
`;

const InfoOverlay = styled.div`
  position: absolute;
  max-width: 70%;
  max-height: calc(100% - 70px);
  top: calc(50% - 50px);
  transform: translateY(-50%);
  left: 88px;
  background: rgba(10, 10, 10, 0.5);
  border-radius: 4px;
  color: ${(props) => props.theme.white};
  padding: 24px 40px;

  & > div > a {
    display: flex;
    width: fit-content;
  }

  @media (max-width: 1400px) {
    top: 42%;
  }
`;

const InfoOverlayTitle = styled.p`
  margin: 0;
  font-size: 60px;
  font-weight: 700;
  line-height: 73px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  @media (max-width: 1400px) {
    font-size: 48px;
    line-height: 55px;
  }
`;

const InfoOverlayDescription = styled.p`
  margin: 20px 0 0 0;
  font-size: 23px;
  font-weight: 500;
  line-height: 32px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;

  @media (max-width: 1400px) {
    font-size: 20px;
    line-height: 28px;
  }
`;

const InfoOverlayButton = styled.div`
  background-color: ${(props) => props.theme.white};
  width: fit-content;
  height: fit-content;
  padding: 16px 32px;
  margin-top: 24px;
  outline: none;
  cursor: pointer;
  font-size: 16px;
  line-height: 20px;
  font-weight: 800;
  text-transform: uppercase;
  color: ${(props) => props.theme.secondary12Color};
  border: 0;
  border-radius: 4px;
  text-decoration: none;
`;

export {
  Image,
  Wrapper,
  ImageWrapper,
  PromotionText,
  PromotionWrapper,
  InfoOverlayWrapper,
  InfoOverlayContainer,
  InfoOverlay,
  InfoOverlayTitle,
  InfoOverlayDescription,
  InfoOverlayButton,
};
