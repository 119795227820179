import PropTypes from 'prop-types';

import { MenuItemShape } from 'components/ui/SubmenuBox/SubmenuBox.shapes';

export const HorizontalMenuItemShape = PropTypes.shape({
  value: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  items: PropTypes.arrayOf(MenuItemShape),
  href: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  params: PropTypes.object,  
  route: PropTypes.string,
});
