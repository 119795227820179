const POIHealthIcon = (props = {}) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 329.19 329.19'
    width='18'
    {...props}
  >
    <g>
      <g>
        <rect x='32.911' y='311.672' width='50.816' height='9.211' />
        <path
          d='M127.34,34.496c0-5.094,4.147-9.242,9.242-9.242h53.212c5.094,0,9.242,4.147,9.242,9.242v15.165h16.947V34.496
			c0-14.444-11.75-26.189-26.189-26.189h-53.212c-14.443,0-26.189,11.745-26.189,26.189v15.165h16.947V34.496z'
        />
        <path
          d='M303.759,61.105H25.426C11.387,61.105,0,72.486,0,86.531v191.708c0,14.039,11.382,25.426,25.426,25.426h278.339
			c14.039,0,25.426-11.382,25.426-25.426V86.525C329.185,72.486,317.804,61.105,303.759,61.105z M164.593,253.56
			c-39.311,0-71.178-31.867-71.178-71.178s31.867-71.178,71.178-71.178c39.311,0,71.178,31.867,71.178,71.178
			S203.904,253.56,164.593,253.56z'
        />
        <polygon
          points='179.85,142.108 149.335,142.108 149.335,167.124 124.319,167.124 124.319,197.64 149.335,197.64 149.335,222.656
			179.85,222.656 179.85,197.64 204.867,197.64 204.867,167.124 179.85,167.124 		'
        />
        <rect x='237.363' y='311.672' width='50.816' height='9.211' />
      </g>
    </g>
  </svg>
);
export default POIHealthIcon;
