const POIRecreationIcon = (props = {}) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='12.97'
    height='19'
    viewBox='0 0 12.97 19'
    {...props}
  >
    <path
      d='M1329.03,1654a10.343,10.343,0,0,1-3.1,2.13c-11.94,4.53-6.91,13.35-6.74,13.38a7.231,7.231,0,0,1,1.57-1.92c5.74-4.42,6.91-9.52,6.91-9.52a18.462,18.462,0,0,1-6.4,9.95c-1.12.84-1.89,2.91-2.21,4.98,0,0,.8-0.34,1.14-0.43a7.009,7.009,0,0,1,.89-2.58c7.14,0.89,9.47-5.14,9.78-7.24A13.455,13.455,0,0,0,1329.03,1654Z'
      transform='translate(-1318.03 -1654)'
    />
  </svg>
);
export default POIRecreationIcon;
