import {
  ContactWrapper,
  Avatar,
  Name,
  Position,
  InfoContainer,
  Row,
  Label,
  Link,
} from './Contact.styles';
import WhatsAppIcon from '../../../../../icons/WhatsApp';

const Contact = ({ name, image, position, phone, alt = '' }) => (
  <ContactWrapper>
    <Avatar src={image} alt={alt} />
    <InfoContainer>
      <Name>{name}</Name>
      <Position>{position}</Position>
      <Row>
        <Label>
          <WhatsAppIcon />
        </Label>
        <Link href={`tel:${phone}`}>{phone}</Link>
      </Row>
    </InfoContainer>
  </ContactWrapper>
);

export default Contact;
