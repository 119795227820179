import { FormattedMessage } from 'react-intl';

import SectionHeader from 'components/ui/SectionHeader';

import messages from './ContactUsTitle.messages';

const ContactUsTitle = () => (
  <div>
    <SectionHeader
      alignCenter
      longBox
      uppercase={false}
      boxPosition='bottom'
      size='large'
      boldText={<FormattedMessage {...messages.contact} />}
      lightText={<FormattedMessage {...messages.us} />}
      topHeaderDescriptionText={<FormattedMessage {...messages.office} />}
    />
  </div>
);

export default ContactUsTitle;
