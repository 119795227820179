import styled, { css } from 'styled-components';

const FavouritesContainer = styled.div`
  font-size: initial;
  width: 20px;
  position: relative;
  margin: 20px 10px 0 25px;

  svg {
    color: ${(props) => props.theme.primary3Color};
  }

  ${({ mobileVersion }) => mobileVersion &&
    css`
      svg {
        color: ${(props) => props.theme.secondary15Color};
      }
    `}
`;

const FavouritesCountContainer = styled.p`
  background: white;
  border-radius: 100%;
  width: 19px;
  height: 19px;
  border: 1px solid ${(props) => props.theme.secondary7Color};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  font-weight: 600;
  position: absolute;
  top: -7px;
  left: 9px;

  ${({ mobileVersion }) => mobileVersion &&
    css`
      width: 15px;
      height: 15px;
      color: ${(props) => props.theme.secondary7Color};
      font-size: 10px;
      top: -3px;
      left: 8px;
    `}
`;

export { FavouritesContainer, FavouritesCountContainer };
