import Link from './components/Link';

import { Wrapper, Item } from './SocialLinks.styles';

const SocialLinks = ({ items = [], darkColor = false, smallIcons = false }) => (
  <Wrapper>
    {items.map((el) => (
      <Item key={el.id} $smallPadding={smallIcons}>
        <Link {...el} darkColor={darkColor} smallIcon={smallIcons} tracked />
      </Item>
    ))}
  </Wrapper>
);

export default SocialLinks;
