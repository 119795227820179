import { cloneElement } from 'react';
import { withTheme } from 'styled-components';

import { Wrapper } from './Box.styles';

const Box = ({ icon = null, theme = null }) => (
  <Wrapper>
    {cloneElement(icon, {
      fill: theme.primary2Color,
    })}
  </Wrapper>
);

export default withTheme(Box);
