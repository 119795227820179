import LogoImage from 'components/icons/Logo';
import Link from 'components/ui/Link';

import { Wrapper } from './Logo.styles';

const Logo = () => (
  <Wrapper>
    <Link href='/'>
      <LogoImage alt='logo' width='100%' height='100%' />
    </Link>
  </Wrapper>
);

export default Logo;
