const MenuArrowIcon = (props = {}) => (
  <svg
    enableBackground='new 0 0 7 9'
    version='1.1'
    viewBox='0 0 7 9'
    space='preserve'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <polygon className='st0' points='7 4.5 0 0 0 9' />
  </svg>
);
export default MenuArrowIcon;
