import { filter, isEqual, includes } from 'lodash';
import translateMenu from 'utils/translateMenu';
import messages from './PageLayout.messages';

export const getFooterSections = (intl, menu) => ({
  aboutUs: {
    title: '',
    content: (
      <div
        dangerouslySetInnerHTML={{
          __html: intl.formatHTMLMessage(messages.aboutUsDescription),
        }}
      />
    ),
  },
  offer: {
    title: intl.formatMessage(messages.offerTitle),
    items: translateMenu(menu.offer || [], intl),
  },
  start: {
    title: intl.formatMessage(messages.startTitle),
    items: translateMenu(menu.start || [], intl),
  },
  informations: {
    title: intl.formatMessage(messages.informationsTitle),
    items: translateMenu(
      [
        {
          translatable: true,
          value: 'contact',
          route: 'contact',
        },
      ],
      intl
    ),
  },
});

export const filterMapPoints = ({ mapPoints, type, ids }) => {
  if (type) {
    return filter(
      mapPoints,
      (point) => isEqual(point.type, type) && !point.hideOnContactMap
    );
  }
  if (ids) {
    return filter(
      mapPoints,
      (point) => includes(ids, point.id) && !point.hideOnContactMap
    );
  }
  return [];
};

export const getInvestmentMapPoints = ({ mapPoints }) =>
  mapPoints.filter(
    (point) =>
      point.type !== 'Office' ||
      (point.type === 'Office' && !point.hideOnInvestmentMap)
  );
