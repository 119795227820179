import { FormattedMessage } from 'react-intl';
import { Button } from './SearchButton.styles';
import messages from './SearchButton.messages';
import MagnifierIcon from '../../../icons/Magnifier';

const SearchButton = () => (
  <Button href='/wyszukiwarka'>
    <MagnifierIcon white />
    <FormattedMessage {...messages.text} />
  </Button>
);

export default SearchButton;
