const RightRedArrowIcon = (props = {}) => (
  <svg
    width='6'
    height='10'
    viewBox='0 0 6 10'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M0.779297 8.71865L0.779297 1.28115C0.779297 0.612401 1.58867 0.278026 2.06055 0.749901L5.7793 4.46865C6.07305 4.7624 6.07305 5.2374 5.7793 5.52803L2.06055 9.2499C1.58867 9.72178 0.779297 9.3874 0.779297 8.71865Z'
      fill='#E30613'
    />
  </svg>
);
export default RightRedArrowIcon;
