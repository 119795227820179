import { FormattedMessage } from 'react-intl';

import Magnifier from 'components/icons/Magnifier';
import messages from './SearchButton.messages';
import { SearchButton, Icon } from './SearchButton.styles';

const Button = ({ onClick, showText = true }) => (
  <SearchButton onClick={onClick}>
    <Icon>
      <Magnifier />
    </Icon>
    {showText ? <FormattedMessage {...messages.text} /> : null}
  </SearchButton>
);

export default Button;
