import { VisibilityContainer } from 'utils/styles';

import {
  Wrapper,
  BoldText,
  LightText,
  RegularText,
  TopHeader,
  SuperLightText,
} from './SectionHeader.styles';

function SectionHeader({
  titleRef = null,
  black = false,
  bold = false,
  boxPosition = 'left',
  children = null,
  light = false,
  lightTextColor = false,
  longBox = false,
  narrowBox = false,
  size = 'small',
  uppercase = true,
  alignCenter = false,
  boldText = '',
  lightText = '',
  regularText = '',
  superLightText = '',
  topHeaderDescriptionText = '',
  alignTextLeft = false,
  greyTextColor = false,
  shortUnderline = false,
}) {
  return (
    <Wrapper
      $greyTextColor={greyTextColor}
      $boxPosition={boxPosition}
      $uppercase={uppercase}
      $size={size}
      $longBox={longBox}
      $narrowBox={narrowBox}
      $bold={bold}
      $black={black}
      $light={light}
      $lightTextColor={lightTextColor}
      $alignCenter={alignCenter}
      $alignTextLeft={alignTextLeft}
      $shortUnderline={shortUnderline}
    >
      {topHeaderDescriptionText && (
        <VisibilityContainer $md $lg>
          <TopHeader>{topHeaderDescriptionText}</TopHeader>
        </VisibilityContainer>
      )}
      {boldText && <BoldText ref={titleRef}>{boldText}&nbsp;</BoldText>}
      {regularText && <RegularText ref={titleRef}>{regularText}</RegularText>}
      {lightText && <LightText ref={titleRef}>{lightText}</LightText>}
      {superLightText && (
        <SuperLightText ref={titleRef}>{superLightText}</SuperLightText>
      )}
      {children}
    </Wrapper>
  );
}

export default SectionHeader;
