import React, { useEffect } from 'react';
import { push as PushMenu } from 'react-burger-menu';

import { SCROLLABLE_ELEMENT_CLASS_NAME } from 'utils/bodyScrollLock';

import FooterCopyright from 'components/FooterCopyright/FooterCopyright';
import Menu from './components/Menu';

import {
  Wrapper,
  MenuWrapper,
  FooterContainer,
  MenuContentWrapper,
} from './BurgerMenu.styles';
import SearchButton from '../SearchButton/SearchButton';

const BurgerMenu = ({
  opened = false,
  onStateChange,
  menuItems = [],
  toggleBurgerMenu,
}) => {
  useEffect(() => {
    if (opened) {
      toggleBurgerMenu();
    }
  }, [typeof window !== 'undefined' ? window.location.hash : '']);

  return (
    <Wrapper active={opened}>
      <PushMenu
        right
        onStateChange={onStateChange}
        width='100%'
        noOverlay
        isOpen={opened}
      >
        <MenuWrapper>
          <SearchButton />
          <MenuContentWrapper className={SCROLLABLE_ELEMENT_CLASS_NAME}>
            <Menu menuItems={menuItems} />
          </MenuContentWrapper>
          <FooterContainer active={opened}>
            <FooterCopyright />
          </FooterContainer>
        </MenuWrapper>
      </PushMenu>
    </Wrapper>
  );
};

export default BurgerMenu;
