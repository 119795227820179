import HorizontalMenuEntry from './components/HorizontalMenuEntry';
import { Wrapper, ItemWrapper } from './HorizontalMenu.styles';

const HorizontalMenu = ({ items = [] }) => (
  <Wrapper>
    {items
      .filter((el) => !el.hidden)
      .map((el, key) => {
        const isLast = key === items.length - 1;
        return (
          <ItemWrapper key={`horizontal-menu-item-${key}`}>
            <HorizontalMenuEntry {...el} last={isLast}>
              {el.value}
            </HorizontalMenuEntry>
          </ItemWrapper>
        );
      })}
  </Wrapper>
);

export default HorizontalMenu;
