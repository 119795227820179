const ClockIcon = (props = {}) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='17'
    height='17'
    viewBox='0 0 17 17'
    {...props}
  >
    <path
      d='M451.321,1093.19a8.383,8.383,0,1,0,.679,3.3A8.334,8.334,0,0,0,451.321,1093.19Zm-1.356,7.06a7.481,7.481,0,0,1-2.733,2.72,7.3,7.3,0,0,1-3.746,1.01,7.2,7.2,0,0,1-2.9-.6,7.31,7.31,0,0,1-3.969-3.98,7.528,7.528,0,0,1,.41-6.65,7.492,7.492,0,0,1,2.723-2.72,7.433,7.433,0,0,1,7.482,0,7.486,7.486,0,0,1,2.732,2.72A7.508,7.508,0,0,1,449.965,1100.25Zm-5.94-3.83v-4.82a0.534,0.534,0,0,0-.539-0.52,0.522,0.522,0,0,0-.511.52v4.89a0.693,0.693,0,0,1,.018.07,0.486,0.486,0,0,0,.14.42l2.779,2.78a0.521,0.521,0,0,0,.716,0,0.5,0.5,0,0,0,0-.72Z'
      transform='translate(-435 -1088)'
    />
  </svg>
);
export default ClockIcon;
