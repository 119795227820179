import { VisibilityContainer } from 'utils/styles';
import Logo from './components/Logo';
import HorizontalMenu from './components/HorizontalMenu';
import Favourites from './components/Favourites/Favourites';

import {
  FixedWrapper,
  HeaderPlaceholder,
  Wrapper,
  LogoWrapper,
  MenuWrapper,
} from './Header.styles';

const Header = ({
  menuItems = [],
  favouritesCount,
  isDesktopStickyBarShown,
}) => (
  <>
    <FixedWrapper $isDesktopStickyBarShown={isDesktopStickyBarShown}>
      <Wrapper>
        <LogoWrapper>
          <Logo />
        </LogoWrapper>
        <MenuWrapper>
          <HorizontalMenu items={menuItems} />
          <VisibilityContainer $md $lg>
            <Favourites favouritesCount={favouritesCount} />
          </VisibilityContainer>
        </MenuWrapper>
      </Wrapper>
    </FixedWrapper>
    <HeaderPlaceholder />
  </>
);

export default Header;
