const POIShopIcon = (props = {}) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='18'
    height='18'
    viewBox='0 0 18 18'
    {...props}
  >
    <path
      d='M1023.4,1669.4a1.8,1.8,0,1,0,1.8,1.8A1.805,1.805,0,0,0,1023.4,1669.4ZM1018,1655v1.8h1.8l3.24,6.84-1.26,2.16a3.2,3.2,0,0,0-.18.9,1.805,1.805,0,0,0,1.8,1.8h10.8v-1.8h-10.44a0.194,0.194,0,0,1-.18-0.18v-0.09l0.81-1.53h6.66a1.641,1.641,0,0,0,1.53-.9l3.24-5.85a0.5,0.5,0,0,0,.18-0.45,0.85,0.85,0,0,0-.9-0.9h-13.32l-0.81-1.8H1018Zm14.4,14.4a1.8,1.8,0,1,0,1.8,1.8A1.805,1.805,0,0,0,1032.4,1669.4Z'
      transform='translate(-1018 -1655)'
    />
  </svg>
);
export default POIShopIcon;
