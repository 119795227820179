import styled, { css } from 'styled-components';

const Wrapper = styled.div`
  color: ${(props) => props.theme.primary3Color};
  font-weight: 400;
  font-size: 18px;
  cursor: default;
  padding: 0 5px;
  position: relative;
  line-height: 1;
  text-align: center;

  ${(props) => props.$size === 'small' && css`
    font-size: 14px;
    line-height: 14px;
  `};

  ${(props) => props.$size === 'moderate' && css`
    font-size: 22px;
    line-height: 30px;

    @media (max-width: ${props.theme.breakpoints.alg}) {
      font-size: 20px;
      line-height: 26px;
    }
  `};

  ${(props) => props.$size === 'modal' && css`
    font-size: 15px;
    line-height: 20px;

    @media (max-width: ${props.theme.breakpoints.alg}) {
      font-size: 14px;
      line-height: 18px;
    }
  `};

  ${(props) => props.$size === 'medium' && css`
    font-size: 28px;
  `};

  ${(props) => props.$size === 'large' && css`
    font-size: 34px;
    line-height: 41px;

    @media (max-width: ${props.theme.breakpoints.sm}) {
      font-size: 20px;
      line-height: 24px;
    }
  `};

  ${(props) => props.$size === 'xlarge' && css`
    font-size: 48px;
  `};

  ${(props) => props.$uppercase && css`
    text-transform: uppercase;
  `};

  ${(props) => props.$light && css`
    font-weight: 400;
  `};

  ${(props) => props.$bold && css`
    font-weight: 700;
  `};

  ${(props) => props.$black && css`
    font-weight: 900;
  `};

  ${(props) => props.$lightTextColor && css`
    color: ${props.theme.primary2Color};
  `};

  ${(props) => props.$greyTextColor && css`
    color: ${props.theme.secondary13Color};
  `};

  ${(props) => props.$boxPosition === 'left' && css`
    padding-left: 17px;

    &::before {
      left: 0;
      top: 0;
      bottom: 0;
      width: 5px;
    }
  `};

  ${(props) => props.$boxPosition === 'bottom' && css`
    padding-left: 0;
    padding-bottom: 16px;

    &::before {
      left: 0;
      bottom: 0;
      width: 30px;
      height: 3px;
    }
  `};

  ${(props) => props.$boxPosition === 'bottom' && props.$longBox && css`
    &::before {
       width: 80px;
    }
  `};

  ${(props) => props.$boxPosition === 'none' && css`
    padding-left: 0;

    &::before {
       content: none;
    }

    @media (max-width: ${props.theme.breakpoints.sm}) {
      &::before {
       content: none;
      }
    }
  `};

    ${(props) => props.$alignCenter && css`
    &::before {
      left: calc(50% - 45px);

      @media (max-width: ${props.theme.breakpoints.sm}) {
        left: calc(50% - 35px);
      }
    }
  `};

  ${(props) => props.$boxPosition === 'bottom' && props.$narrowBox && css`
    &::before {
      height: 2px;
    }
  `};

  ${({ $alignTextLeft }) => $alignTextLeft && css`
    text-align: left;
  `};

  ${(props) => props.$boxPosition !== 'none' && css`
    &::before {
      display: block;
      content: '';
      background-color: ${props.theme.primary4Color};
      position: absolute;
    }
    @media (max-width: ${props.theme.breakpoints.sm}) {
      padding-bottom: 20px;
      padding-left: 0;
      &::before {
        display: block;
        content: '';
        background-color: ${props.theme.primary4Color};
        position: absolute;
        height: 3px;
        bottom: 5px;
        width: 80px;
      }
    }
  `};

  ${(props) => props.$boxPosition === 'bottom' && props.$shortUnderline && css`
    @media (max-width: ${props.theme.breakpoints.sm}) {
      &::before {
        width: 32px;
      }
    }
  `};
`;

const BoldText = styled.span`
  font-weight: 700;
`;

const RegularText = styled.span`
  font-weight: 500;
`;

const LightText = styled.span`
  font-weight: 400;
`;

const SuperLightText = styled.span`
  font-weight: 300;
`;

const TopHeader = styled.div`
  color: ${(props) => props.theme.primary3Color};
  font-weight: 300;
  font-size: 20px;
  line-height: 24px;
  padding: 0 0 15px;
`;

export {
  Wrapper, BoldText, LightText, RegularText, TopHeader, SuperLightText,
};
