import Box from './components/Box';
import {
  StyledLink,
  Description,
  Wrapper,
  StyledContainer,
} from './ContactIcon.styles';

const ContactIcon = ({
  href = null,
  onClick = () => {},
  icon,
  description = null,
}) => (
  <Wrapper>
    {onClick ? (
      <StyledContainer onClick={onClick}>
        <Box icon={icon} />
        <Description>{description}</Description>
      </StyledContainer>
    ) : (
      <StyledLink href={href}>
        <Box icon={icon} />
        <Description>{description}</Description>
      </StyledLink>
    )}
  </Wrapper>
);

export default ContactIcon;
