const MailIcon = (props = {}) => (
  <svg
    width='31'
    height='31'
    viewBox='0 0 512 512'
    space='preserve'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      className='st0'
      d='M512,106.5c0-23.4-19.1-42.5-42.5-42.5h-427C19.1,64,0,83.1,0,106.5v303.6c0,23.4,19.1,42.5,42.5,42.5h427  c23.4,0,42.5-19.1,42.5-42.5V106.5z M470.5,88.3L257,301.9l-2.1-2.1L41.6,88.3H470.5z M24.3,105.4l153.5,152.2L24.3,411.1V105.4z   M41.5,428.3l153.6-153.6l53.4,52.9c4.7,4.7,12.4,4.7,17.1,0l52.1-52.1l152.8,152.8H41.5z M487.7,411.1L334.9,258.3l152.8-152.8  V411.1z'
    />
  </svg>
);
export default MailIcon;
