import Link from 'components/ui/Link';
import MenuArrow from 'components/icons/MenuArrow';

import { Wrapper } from './SubmenuLink.styles';

function SubmenuLink({
  children,
  showArrow = true,
  href = '',
  scroll = false,
  route = '',
  params = {},
  active = false,
  trackPage = () => {},
  elementId = '',
}) {
  return (
    <Link
      href={
        href || {
          pathname: '/[investmentSlug]',
          query: { investmentSlug: params.id },
          hash: `#${elementId}`,
        }
      }
      scroll={scroll}
      active={href !== ''}
      params={params}
      onClick={
        href !== ''
          ? () => trackPage(params.id ? `${params.id}/${route}` : route)
          : null
      }
    >
      <Wrapper active={active}>
        {children}
        {showArrow ? <MenuArrow width={8} height={8} /> : null}
      </Wrapper>
    </Link>
  );
}

export default SubmenuLink;
