import SectionHeader from 'components/ui/SectionHeader';
import ModalInfoClose from 'components/icons/ModalInfoClose';
import RightRedArrow from 'components/icons/RightRedArrow';
import { FormattedMessage } from 'react-intl';
import messages from './MapInfoOverlay.messages';
import {
  Wrapper,
  TitleWrapper,
  Title,
  ButtonsWrapper,
  NavigateButton,
  ShowInvestmentButton,
  Thumbnail,
} from './MapInfoOverlay.styles';

const INVESTMENT_MARKER_TYPE = 'Investment';

export const MapInfoOverlay = ({
  isMarkedEntityInfoShown,
  activeMarkedEntityData = null,
  handleMarkedEntityInfoClose,
  handleShowInvestmentButtonClick,
}) => {
  function handleNavigateButtonClick() {
    if (activeMarkedEntityData) {
      if (
        navigator.userAgent.includes('iPhone') ||
        navigator.userAgent.includes('iPad') ||
        navigator.userAgent.includes('iPod')
      ) {
        window.open(
          `http://maps.apple.com/?q=${activeMarkedEntityData.lat},${activeMarkedEntityData.lng}&ll=${activeMarkedEntityData.lat},${activeMarkedEntityData.lng}`
        );
      } else {
        window.open(
          `https://maps.google.com/maps?daddr=${activeMarkedEntityData.lat},${activeMarkedEntityData.lng}&amp;ll=`
        );
      }
    }
  }

  return (
    <>
      {activeMarkedEntityData && (
        <Thumbnail
          src={activeMarkedEntityData.picture || 'static/img/logoMobile.png'}
          isMarkedEntityInfoShown={isMarkedEntityInfoShown}
          isPlaceholderImg={!activeMarkedEntityData.picture}
        />
      )}
      <Wrapper isMarkedEntityInfoShown={isMarkedEntityInfoShown}>
        {activeMarkedEntityData && (
          <>
            <TitleWrapper>
              <Title>{activeMarkedEntityData.investmentTitle}</Title>
              <ModalInfoClose
                onClick={handleMarkedEntityInfoClose}
                style={{ cursor: 'pointer' }}
              />
            </TitleWrapper>
            <SectionHeader
              longBox
              shortUnderline
              uppercase={false}
              boxPosition='bottom'
              size='small'
              lightText={activeMarkedEntityData.title}
              alignTextLeft
            />
            <ButtonsWrapper>
              {activeMarkedEntityData.lat && activeMarkedEntityData.lng && (
                <NavigateButton onClick={handleNavigateButtonClick}>
                  <FormattedMessage {...messages.navigate} />
                </NavigateButton>
              )}
              {activeMarkedEntityData.type === INVESTMENT_MARKER_TYPE && (
                <ShowInvestmentButton onClick={handleShowInvestmentButtonClick}>
                  <FormattedMessage {...messages.goToInvestment} />
                  <RightRedArrow />
                </ShowInvestmentButton>
              )}
            </ButtonsWrapper>
          </>
        )}
      </Wrapper>
    </>
  );
};
