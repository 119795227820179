const FullArrowIcon = (props = {}) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='4'
    height='5'
    viewBox='0 0 4 5'
    {...props}
  >
    <path d='M434,422v4.985l3.99-2.492Z' transform='translate(-434 -422)' />
  </svg>
);
export default FullArrowIcon;
