import styled, { css } from 'styled-components';

const Wrapper = styled.div`
  display: flex;
`;

const Item = styled.div`
  padding: 0 15px;

  svg {
    color: ${(props) => props.theme.secondary12Color};
    width: 35px;
    height: auto;
    margin-top: -11px;

    @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
      width: 20px;
    }
  }

  ${({ $smallPadding }) => $smallPadding && css`
    padding: 0 7px;
  `}
`;

export { Item, Wrapper };
