import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  background-color: ${(props) => props.theme.primary2Color};
  box-shadow: ${(props) =>
    props.$isDesktopStickyBarShown
      ? 'none'
      : `0 5px 15px -12px rgba(${props.theme.shadowColor})`};
  justify-content: space-between;
  padding: 0 15px;
  align-items: center;
  position: fixed;
  top: 0;
  z-index: 99;
`;

const MenuWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;

  .bm-menu-wrap {
    z-index: -99999999 !important;
  }
`;

const HeaderPlaceholder = styled.div`
  height: 50px;
`;

const FavouritesWrapper = styled.div`
  position: absolute;
  top: -7px;
  right: 142px;

  svg {
    height: 15px;
    width: auto;
  }
`;

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export { Wrapper, MenuWrapper, HeaderPlaceholder, FavouritesWrapper, Row };
