const SlideArrowIcon = (props = {}) => (
  <svg
    width='21'
    height='16'
    viewBox='0 0 21 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M20 8H2'
      stroke='#0A0A0A'
      strokeWidth='2'
      strokeMiterlimit='10'
      strokeLinecap='round'
    />
    <path
      d='M9 15L2 8L9 1'
      stroke='#0A0A0A'
      strokeWidth='2'
      strokeMiterlimit='10'
      strokeLinecap='round'
    />
  </svg>
);
export default SlideArrowIcon;
