const MagnifierIcon = (props = {}) =>
  props.white ? (
    <svg
      width='25'
      height='25'
      viewBox='0 0 512 512'
      space='preserve'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        className='st0'
        d='m507.5 485.9-124.4-124.4c33.4-38.4 53.6-88.4 53.6-143.2 0-120.4-98-218.3-218.3-218.3-120.5 0-218.3 98-218.3 218.3s98 218.3 218.3 218.3c54.7 0 104.8-20.2 143.2-53.6l124.4 124.4c3 3 6.9 4.5 10.8 4.5s7.8-1.5 10.8-4.5c5.8-5.8 5.8-15.6-0.1-21.5zm-476.9-267.6c0-103.5 84.2-187.7 187.7-187.7s187.7 84.2 187.7 187.7-84.1 187.8-187.7 187.8c-103.5 0-187.7-84.3-187.7-187.8z'
        fill='#fff'
      />
    </svg>
  ) : (
    <svg
      width='25'
      height='25'
      viewBox='0 0 512 512'
      space='preserve'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        className='st0'
        d='m507.5 485.9-124.4-124.4c33.4-38.4 53.6-88.4 53.6-143.2 0-120.4-98-218.3-218.3-218.3-120.5 0-218.3 98-218.3 218.3s98 218.3 218.3 218.3c54.7 0 104.8-20.2 143.2-53.6l124.4 124.4c3 3 6.9 4.5 10.8 4.5s7.8-1.5 10.8-4.5c5.8-5.8 5.8-15.6-0.1-21.5zm-476.9-267.6c0-103.5 84.2-187.7 187.7-187.7s187.7 84.2 187.7 187.7-84.1 187.8-187.7 187.8c-103.5 0-187.7-84.3-187.7-187.8z'
      />
    </svg>
  );

export default MagnifierIcon;
