import { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import Select from 'components/forms/Select';
import Button from 'components/forms/SearchButton';
import { SelectOptionShape } from 'components/forms/Select/Select.shapes';

import intlShape from 'utils/shapes/intlShape';

import trackYouleadVirtualPageView from 'utils/YouLead/TrackYouleadVirtualPageView';
import messages from './QuickSearchForm.messages';
import {
  Wrapper,
  Row,
  Margin,
  SearchButtonWrapper,
} from './QuickSearchForm.styles';
import RangeSlider from '../forms/RangeSlider';
import { VisibilityContainer } from '../../utils/styles';

class QuickSearchForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      investmentSelected: null,
      priceMinSelected: props.priceOptions.min,
      priceMaxSelected: props.priceOptions.max,
      roomMinSelected: props.roomsOptions.min,
      roomMaxSelected: props.roomsOptions.max,
      surfaceMinSelected: props.surfaceOptions.min,
      surfaceMaxSelected: props.surfaceOptions.max,
    };
  }

  onButtonClick = () => {
    const params = {
      investmentsSelected: this.state.investmentSelected,
      priceMinSelected: this.state.priceMinSelected,
      priceMaxSelected: this.state.priceMaxSelected,
      roomMinSelected: this.state.roomMinSelected,
      roomMaxSelected: this.state.roomMaxSelected,
      surfaceMinSelected: this.state.surfaceMinSelected,
      surfaceMaxSelected: this.state.surfaceMaxSelected,
      viewsSelected: null,
      typeSelected: 14,
      onlyPromo: null,
      expandFirst: null,
    };

    if (this.state.priceMinSelected === this.props.priceOptions.min) {
      delete params.priceMinSelected;
    }

    if (this.state.priceMaxSelected === this.props.priceOptions.max) {
      delete params.priceMaxSelected;
    }

    if (this.state.surfaceMinSelected === this.props.surfaceOptions.min) {
      delete params.surfaceMinSelected;
    }

    if (this.state.surfaceMaxSelected === this.props.surfaceOptions.max) {
      delete params.surfaceMaxSelected;
    }

    if (this.state.roomMinSelected === this.props.roomsOptions.min) {
      delete params.roomMinSelected;
    }

    if (this.state.roomMaxSelected === this.props.roomsOptions.max) {
      delete params.roomMaxSelected;
    }

    this.props.onButtonClick(params);
  };

  onInvestmentSelectChange = (item) => {
    this.setState((prevState) => {
      const newState = prevState;
      if (!item.value) {
        delete newState.investmentSelected;
        return newState;
      }

      return {
        investmentSelected: item.value,
      };
    });
  };

  handlePriceChange = (price) => {
    this.setState(() => ({
      priceMinSelected: price[0],
      priceMaxSelected: price[1],
    }));
  };

  handleSurfaceAreaChange = (area) => {
    this.setState(() => ({
      surfaceMinSelected: area[0],
      surfaceMaxSelected: area[1],
    }));
  };

  handleRoomsChange = (rooms) => {
    this.setState(() => ({
      roomMinSelected: rooms[0],
      roomMaxSelected: rooms[1],
    }));
  };

  render() {
    return (
      <VisibilityContainer $md $lg>
        <Wrapper>
          <Row>
            <Margin>
              <Select
                withEmptyOption
                placeHolder={this.props.intl.formatMessage(messages.investment)}
                values={this.props.investmentOptions}
                selectedOption={this.state.investmentSelected}
                onChange={this.onInvestmentSelectChange}
              />
            </Margin>
            <Margin>
              <RangeSlider
                title={this.props.intl.formatMessage(messages.price)}
                sliderMin={this.props.priceOptions.min}
                sliderMax={this.props.priceOptions.max}
                firstDisplayValue={this.state.priceMinSelected}
                secondDisplayValue={this.state.priceMaxSelected}
                formatDisplay='zł'
                handleChange={this.handlePriceChange}
              />
            </Margin>
            <Margin>
              <RangeSlider
                title={this.props.intl.formatMessage(messages.surface)}
                sliderMin={this.props.surfaceOptions.min}
                sliderMax={this.props.surfaceOptions.max}
                firstDisplayValue={this.state.surfaceMinSelected}
                secondDisplayValue={this.state.surfaceMaxSelected}
                formatDisplay='m²'
                handleChange={this.handleSurfaceAreaChange}
              />
            </Margin>
            <Margin>
              <RangeSlider
                title={this.props.intl.formatMessage(messages.rooms)}
                sliderMin={this.props.roomsOptions.min}
                sliderMax={this.props.roomsOptions.max}
                firstDisplayValue={this.state.roomMinSelected}
                secondDisplayValue={this.state.roomMaxSelected}
                formatDisplay=''
                handleChange={this.handleRoomsChange}
                isWithoutExtraPadding
              />
            </Margin>
          </Row>
          <SearchButtonWrapper>
            <Button
              onClick={() => {
                this.onButtonClick();
                trackYouleadVirtualPageView('homePageSearch');
              }}
              showText
            />
          </SearchButtonWrapper>
        </Wrapper>
      </VisibilityContainer>
    );
  }
}

QuickSearchForm.propTypes = {
  intl: intlShape.isRequired,
  investmentOptions: PropTypes.arrayOf(SelectOptionShape).isRequired,
  priceOptions: PropTypes.shape({
    min: PropTypes.number,
    max: PropTypes.number,
  }).isRequired,
  roomsOptions: PropTypes.shape({
    min: PropTypes.number,
    max: PropTypes.number,
  }).isRequired,
  surfaceOptions: PropTypes.shape({
    min: PropTypes.number,
    max: PropTypes.number,
  }).isRequired,
  onButtonClick: PropTypes.func.isRequired,
};

export default injectIntl(QuickSearchForm);
