import includes from 'lodash/includes';

import GoogleMaps from 'components/GoogleMaps';
import ContactUsBlock from 'components/content/ContactUsBlock';
import ContactUsTitle from 'components/content/ContactUsBlock/components/ContactUsTitle';

import { ContainerMain } from 'utils/styles';
import config from 'config';
import withSizes from 'utils/withSizes';

import { Waypoint } from 'react-waypoint';
import trackYouleadCustomEvent from 'utils/YouLead/TrackYouleadCustomEvent';
import { withRouter } from 'next/router';
import { Column, RightContainer, Wrapper } from './ContactWithMap.styles';

const ContactWithMap = ({
  contactItems = [],
  mapPoints = [],
  showContactData = true,
  staticMapUrl = null,
  centered = false,
  screenType = '',
  onPositionChange = () => {},
  router,
  showLoader,
}) => {
  const { desktopMapZoom, mobileMapZoom, cityCenter } = config;
  const isMediumOrBigger = includes(['md', 'lg'], screenType);
  const isMobile = includes(['xs'], screenType);
  const zoomValue = isMediumOrBigger ? desktopMapZoom : mobileMapZoom;
  const zoom = centered ? zoomValue : null;
  const center = centered ? cityCenter : null;
  return (
    <Waypoint
      bottomOffset={400}
      onPositionChange={({ currentPosition }) => {
        onPositionChange({
          id: config.locationIds.localization,
          position: currentPosition,
        });
      }}
    >
      <div>
        <Wrapper>
          <Column id={config.locationIds.investmentMap}>
            <GoogleMaps
              points={mapPoints}
              onMarkerClick={({ id, slug, type, route, externalURL, name }) => {
                if (type === 'Investment' && id && slug && !externalURL) {
                  showLoader();
                  router.push(`${slug}`);
                } else if (type === 'Office' && route) {
                  window.open(route, '_blank');
                  trackYouleadCustomEvent('clickGoogleRouteMarker', { name });
                } else if (externalURL) {
                  window.open(externalURL, '_blank');
                  trackYouleadCustomEvent('clickGoogleRouteMarker', {
                    externalURL,
                  });
                }
              }}
              staticMapUrl={staticMapUrl}
              center={center}
              zoom={zoom}
              isOldMarkerStyle={isMobile}
              isMapWithMobileModal
            />
          </Column>
          {!showContactData ? null : (
            <ContainerMain>
              <RightContainer>
                <ContactUsBlock
                  title={<ContactUsTitle />}
                  items={contactItems}
                  transparentBackground
                  alignCenter
                />
              </RightContainer>
            </ContainerMain>
          )}
        </Wrapper>
      </div>
    </Waypoint>
  );
};

export default withRouter(withSizes()(ContactWithMap));
