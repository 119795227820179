import styled, { css } from 'styled-components';

const SliderWrapper = styled.div`
  position: relative;
  width: 100%;

  ${({ $isLocalVariant }) =>
    $isLocalVariant &&
    css`
      & .slider-control-bottomcenter {
        bottom: -50px !important;
      }
    `}

  & .slider-control-centerleft {
    display: flex;
    justify-content: flex-end;
    position: absolute;
    top: unset !important;
    bottom: 100px;
    right: 0;
    left: calc(50% - 23px) !important;
    width: 100%;
    max-width: 1124px;
    transform: translateX(-50%) !important;
    z-index: 1;
  }

  & .slider-control-centerright {
    display: flex;
    justify-content: flex-end;
    position: absolute;
    top: unset !important;
    bottom: 100px;
    right: 0;
    left: 50% !important;
    width: 100%;
    max-width: 1170px;
    transform: translateX(-50%) !important;
    pointer-events: none !important;
  }
`;

export { SliderWrapper };
