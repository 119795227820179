import styled from 'styled-components';

const Wrapper = styled.div`
  position: relative;
  height: 100%;
  margin-left: 5px;
  aspect-ratio: 5 / 2;

  & > div {
    height: 100%;
  }

  > svg {
    position: absolute;
    width: auto;
    height: 100%;
  }
`;

export { Wrapper };
