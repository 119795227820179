import styled from 'styled-components';
import { ContainerMain } from 'utils/styles';
import Link from 'components/ui/Link';

const Wrapper = styled.div`
  background: ${(props) => props.theme.white};

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    width: 100%;
  }
`;

const Container = styled(ContainerMain)`
  padding: 18px 0 18px !important;
  display: flex;

  flex-wrap: wrap;
  background: ${(props) => props.theme.white};
  color: ${(props) => props.theme.black};
  font-weight: 300;
  justify-content: space-between;
  align-items: center;
  font-size: 13px;
  line-height: 16px;

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    justify-content: center;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    font-size: 12px;
    padding: 20px 0 20px !important;
    line-height: 15px;
    flex-direction: column;
    align-items: center;
    border-top: 1px solid ${(props) => props.theme.secondary16Color};
  }
`;

const CopyrightWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  @media (min-width: ${(props) => props.theme.breakpoints.sm}) {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.xs}) {
    margin-top: -15px;
    flex-basis: 100%;
    text-align: center;
  }
`;

const SocialLinksWrapper = styled.div`
  display: flex;
  align-items: center;

  & > div > div {
    padding: 0;

    & > a {
      display: flex;

      & > svg {
        max-width: 24px;
        margin-top: 0;
      }
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    align-items: center;
  }
`;

const RedirectsWrapper = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;
    margin-top: 16px;
  }
`;

const InformationLinkWrapper = styled(Link)`
  padding-right: 15px;
  text-decoration: underline;

  span {
    text-decoration: underline;
    text-decoration-color: ${(props) => props.theme.primary3Color};
  }

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    &::before {
      content: '|';
      margin: 0 5px;
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    &::before {
      content: '';
    }
    padding-right: 0;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    padding-right: 24px;
  }
`;

export {
  CopyrightWrapper,
  SocialLinksWrapper,
  Container,
  Wrapper,
  InformationLinkWrapper,
  RedirectsWrapper,
};
