import { injectIntl } from 'react-intl';
import Select from 'react-select';
import find from 'lodash/find';

import { SelectWrapper, SelectStyles, Label } from './Select.styles';
import { formatValues } from './utils';
import messages from './Select.messages';

const SelectOption = ({
  values,
  selectedOption = undefined,
  placeHolder = null,
  intl,
  onChange = () => {},
  onMenuClose = () => {},
  name = null,
  withEmptyOption = false,
  optionComponent = null,
  height = null,
}) => {
  const options = formatValues(
    values,
    withEmptyOption,
    intl.formatMessage(messages.all)
  );
  const selected = find(options, (e) => e.value === selectedOption) || null;
  const placeholder = placeHolder || intl.formatMessage(messages.all);
  const components = {};

  if (optionComponent) components.Option = optionComponent;

  const customHeightStyles = {
    control: () => ({
      height: `${height}px`,
    }),
  };

  return (
    <SelectWrapper>
      <Label>{name}</Label>
      <Select
        isSearchable={false}
        options={options}
        noOptionsMessage={() => intl.formatMessage(messages.noOptions)}
        placeholder={placeholder}
        styles={{ ...customHeightStyles, ...SelectStyles }}
        onChange={onChange}
        onMenuClose={onMenuClose}
        value={selected}
        components={components}
        height={height}
        className='react-select'
      />
    </SelectWrapper>
  );
};

export default injectIntl(SelectOption);
