import styled from 'styled-components';

const Wrapper = styled.div`
  position: absolute;
  bottom: ${(props) => (props.isMarkedEntityInfoShown ? '0' : '-151px')};
  width: 100%;
  height: 100%;
  max-height: 155px;
  background-color: white;
  transition: bottom 0.2s ease-out;
  display: flex;
  flex-direction: column;
  border-bottom: 3px solid ${(props) => props.theme.red};
  padding: 16px 16px 16px 16px;
  box-sizing: border-box;
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > svg {
    min-width: 24px;
  }

  &:hover {
    opacity: 0.8;
    transition: opacity 0.2s ease-out;
  }
`;

const Title = styled.p`
  margin: 0;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 4px;
  display: flex;
  justify-content: space-between;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  min-height: 24px;
  max-height: 24px;
  padding-right: 24px;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  padding-bottom: 21px;
`;

const NavigateButton = styled.button`
  all: unset;
  background-color: #F5F5F5;
  color: black;
  cursor: pointer;
  outline: none;
  font-size: 13px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1.2px;
  padding: 8px 15px; 

  &:hover {
    opacity: 0.8;
    transition: opacity 0.2s ease-out;
  }

  `;

const ShowInvestmentButton = styled.button`
  all: unset;
  display: flex;
  align-items: center;
  gap: 8px; 
  border: 0;
  background-color: transparent;
  color: ${(props) => props.theme.red};
  cursor: pointer;
  outline: none;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  text-transform: uppercase;

  &:hover {
    opacity: 0.8;
    transition: opacity 0.2s ease-out;
  }
  `;

const Thumbnail = styled.img`
    position: absolute;
    display: block;
    background-color: ${({ isPlaceholderImg, theme }) => (isPlaceholderImg ? theme.red : 'transparent')};
    bottom: ${({ isMarkedEntityInfoShown }) => (isMarkedEntityInfoShown ? '175px' : '-100px')};
    right: 16px;
    width: 96px;
    height: 96px;
    object-fit: ${({ isPlaceholderImg }) => (isPlaceholderImg ? 'contain' : 'cover')};
    border-radius: 8px;
    transition: bottom 0.2s ease-out;
  `;

export {
  Wrapper, TitleWrapper, Title, ButtonsWrapper, NavigateButton, ShowInvestmentButton, Thumbnail,
};
