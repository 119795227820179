import { Component } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import trackYouleadVirtualPageView from 'utils/YouLead/TrackYouleadVirtualPageView';
import SubmenuLink from './components/SubmenuLink';

import { MenuItemShape } from './SubmenuBox.shapes';
import { MenuWrapper, Wrapper, EntryContainer } from './SubmenuBox.styles';

class SubmenuBox extends Component {
  constructor(props) {
    super(props);

    this.state = {
      expandedMenuIndex: null,
    };
  }

  onHover = (index) => this.setState({ expandedMenuIndex: index });

  isHoverable = (index) =>
    !isEmpty(this.props.items[index]) &&
    !isEmpty(this.props.items[index].items);

  render() {
    return (
      <MenuWrapper $topBorder={this.props.topBorder}>
        <Wrapper>
          {this.props.items
            .filter((el) => !el.hidden)
            .map((el, key) => {
              const url = this.isHoverable(key)
                ? ''
                : el.href || {
                    pathname: `/[investmentSlug]${
                      el.scroll ? `#${el.scroll}` : ''
                    }`,
                    query: { investmentSlug: el.slug || el.id },
                  };

              return (
                <EntryContainer
                  key={`menu-item-${key}`}
                  onClick={() => this.onHover(key)}
                >
                  <SubmenuLink
                    showArrow={false}
                    href={url}
                    params={el.params}
                    scroll={el.scroll === undefined ? true : el.scroll}
                    active={key === this.state.expandedMenuIndex}
                    trackPage={trackYouleadVirtualPageView}
                    elementId={el.elementId}
                  >
                    {el.value}
                  </SubmenuLink>
                </EntryContainer>
              );
            })}
        </Wrapper>
        {!isEmpty(this.props.items[this.state.expandedMenuIndex]) &&
        !isEmpty(this.props.items[this.state.expandedMenuIndex].items) ? (
          <Wrapper
            fullWidth
            onClick={() => this.onHover(this.state.expandedMenuIndex)}
          >
            {this.props.items[this.state.expandedMenuIndex].items
              .filter((el) => !el.hidden)
              .map((el, key) => (
                <div key={`menu-item-${key}`}>
                  <SubmenuLink
                    href={
                      el.params?.id
                        ? {
                            pathname: '/[investmentSlug]',
                            query: { investmentSlug: el.params.id },
                            hash: `#${el.elementId}`,
                          }
                        : el.href
                    }
                    route={el.route}
                    params={el.params}
                    scroll={el.scroll}
                    trackPage={trackYouleadVirtualPageView}
                    elementId={el.elementId}
                  >
                    {el.value}
                  </SubmenuLink>
                </div>
              ))}
          </Wrapper>
        ) : null}
      </MenuWrapper>
    );
  }
}

SubmenuBox.propTypes = {
  items: PropTypes.arrayOf(MenuItemShape).isRequired,
  topBorder: PropTypes.bool,
};

SubmenuBox.defaultProps = {
  topBorder: false,
};

export default SubmenuBox;
