import React, { useEffect } from 'react';
import Carousel from 'nuka-carousel';
import _map from 'lodash/map';

import config from 'config';

import { VisibilityContainer } from 'utils/styles';
import Slide from './components/Slide';
import { SliderWrapper } from './Slider.styles';
import Arrow from './components/Arrow';
import withSizes from 'utils/withSizes';

const Slider = ({
  items = [],
  variant = 'default',
  showModal = null,
  onSliderLoaded = null,
  screenType = '',
}) => {
  const isMobile = screenType === 'xs';
  const isLocalVariant = variant === 'local';

  useEffect(() => {
    onSliderLoaded();
  }, [items, variant, showModal]);

  return (
    <SliderWrapper $isLocalVariant={isLocalVariant}>
      <Carousel
        autoplay
        autoplayInterval={config.slider.autoplayInterval}
        wrapAround
        renderCenterLeftControls={({ previousSlide }) => (
          <VisibilityContainer $lg>
            <Arrow onClick={previousSlide} direction='left' />
          </VisibilityContainer>
        )}
        renderCenterRightControls={({ nextSlide }) => (
          <VisibilityContainer $lg>
            <Arrow onClick={nextSlide} direction='right' />
          </VisibilityContainer>
        )}
        renderBottomCenterControls={null}
        pauseOnHover={false}
        renderAnnounceSlideMessage={() => {}}
        defaultControlsConfig={{
          containerClassName: 'slider-banner',
        }}
        swiping
        dragging={isMobile}
      >
        {_map(items, (el, key) => (
          <Slide key={key} showModal={showModal} {...el} />
        ))}
      </Carousel>
    </SliderWrapper>
  );
};

export default withSizes()(Slider);
