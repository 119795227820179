import { Component } from 'react';
import PropTypes from 'prop-types';
import Iframe from 'react-iframe';
import ReactModal from 'react-modal';

import config from 'config';

import withSizesUtil from 'utils/withSizes';
import Arrow from 'components/Slider/components/Arrow';
import breakpoints from 'theme/breakpoints';
import Close from './components/Close';

import {
  Wrapper,
  CloseWrapper,
  ContentWrapper,
  ControlsWrapper,
  IframeWrapper,
} from './Modal.styles';

const customStyles = {
  content: {
    overflow: 'initial',
    left: '50%',
    right: 'auto',
    transform: 'translateX(-50%)',
    padding: 0,
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    zIndex: 120,
  },
};

class Modal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      mounted: false,
      isMediumScreen: this.props.screenType === 'md',
      isLargeScreen: this.props.screenType === 'lg',
    };
  }

  componentDidMount() {
    ReactModal.setAppElement('#modal-root');
    this.setState({ mounted: true });
  }

  render() {
    if (this.props.obidoData || this.props.isOdysseyUrl) {
      customStyles.content.height = '90dvh';
      customStyles.content.aspectRatio = '97 / 85';

      if (this.props.isOdysseyUrl) {
        customStyles.content.transform = 'translate(-50%, -50%)';
        customStyles.content.height = '70dvh';
        customStyles.content.maxWidth = '90%';
        customStyles.content.aspectRatio = '16 / 9';
        customStyles.content.top = '50%';
        customStyles.content.display = 'flex';
        customStyles.content.alignItems = 'center';

        if (this.props.screenWidth < config.odysseyWidths.lg) {
          customStyles.content.height = '36rem';
        }

        if (this.props.screenWidth < config.odysseyWidths.md) {
          customStyles.content.height = '32rem';
        }

        if (this.props.screenWidth < config.odysseyWidths.sm) {
          customStyles.content.height = '24rem';
        }
      }
    } else {
      customStyles.content.transform = 'translate(-50%, -50%)';
      customStyles.content.maxWidth = '90%';
      customStyles.content.inset = 'none';
      customStyles.content.height = 'fit-content';
      customStyles.content.left = '50%';
      customStyles.content.top = '50%';
      customStyles.content.display = 'flex';
      customStyles.content.alignItems = 'center';

      if (
        this.props.screenWidth < parseInt(breakpoints.sm.replace('px', ''), 10)
      ) {
        customStyles.content.maxWidth = '100%';
        customStyles.content.width = '100%';
        customStyles.content.maxHeight = '100%';
        customStyles.content.height = '100%';
        customStyles.content.justifyContent = 'center';
      }
    }

    if (this.props.small) {
      customStyles.content.top = '50%';
      customStyles.content.bottom = 0;
      customStyles.content.padding = 0;
      customStyles.content.background = '#fff';
      customStyles.content.borderColor = '#fff';
      customStyles.content.width =
        this.state.isLargeScreen || this.state.isMediumScreen
          ? '100%'
          : '360px';
      customStyles.content.height =
        this.state.isLargeScreen || this.state.isMediumScreen
          ? '660px'
          : '714px';
      customStyles.content.transform = 'translateX(-50%) translateY(-50%)';
    }
    return (
      <>
        <div id='modal-root' />
        {this.state.mounted ? (
          <ReactModal
            isOpen={this.props.open}
            onRequestClose={this.props.onModalClose}
            style={customStyles}
            bodyOpenClassName={config.openedModalClassName}
          >
            {this.props.obidoData ? (
              <IframeWrapper>
                <CloseWrapper small={this.props.small}>
                  <Close onClick={this.props.onModalClose} />
                </CloseWrapper>
                <Iframe
                  id='obido-iframe'
                  src={this.props.obidoData}
                  height='100%'
                  width='100%'
                  scrolling='no'
                  allowFullScreen
                />
              </IframeWrapper>
            ) : (
              <Wrapper
                small={this.props.small}
                isStaticHeight={this.props.isStaticHeight}
                isMobileFullScreen={this.props.isMobileFullScreen}
              >
                {this.props.closeButton && (
                  <CloseWrapper small={this.props.small}>
                    <Close
                      onClick={this.props.onModalClose}
                      isMobileFullScreen={this.props.isMobileFullScreen}
                    />
                  </CloseWrapper>
                )}
                <ContentWrapper
                  desktop={
                    this.state.isMediumScreen || this.state.isLargeScreen
                  }
                  small={this.props.small}
                  isPositionDefault={this.props.isPositionDefault}
                  isMobileFullScreen={this.props.isMobileFullScreen}
                >
                  {this.props.children}

                  {this.props.onPrevClick && this.props.onNextClick ? (
                    <ControlsWrapper
                      titleLinesCount={this.props.titleLinesCount}
                      isPositionDefault={this.props.isPositionDefault}
                    >
                      {this.props.showPrev ? (
                        <Arrow
                          onClick={this.props.onPrevClick}
                          direction='left'
                        />
                      ) : (
                        <Arrow
                          onClick={this.props.onPrevClick}
                          direction='left'
                          isDisabled
                        />
                      )}
                      {this.props.showNext ? (
                        <Arrow
                          onClick={this.props.onNextClick}
                          direction='right'
                        />
                      ) : (
                        <Arrow
                          onClick={this.props.onNextClick}
                          direction='right'
                          isDisabled
                        />
                      )}
                    </ControlsWrapper>
                  ) : null}
                </ContentWrapper>
              </Wrapper>
            )}
          </ReactModal>
        ) : null}
      </>
    );
  }
}

Modal.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  onModalClose: PropTypes.func.isRequired,
  closeButton: PropTypes.bool,
  isMobileFullScreen: PropTypes.bool,
  isOdysseyUrl: PropTypes.bool,
  isPositionDefault: PropTypes.bool,
  isStaticHeight: PropTypes.bool,
  obidoData: PropTypes.node,
  open: PropTypes.bool,
  screenType: PropTypes.string,
  screenWidth: PropTypes.number,
  showNext: PropTypes.bool,
  showPrev: PropTypes.bool,
  small: PropTypes.bool,
  titleLinesCount: PropTypes.number,
  onNextClick: PropTypes.func,
  onPrevClick: PropTypes.func,
};

Modal.defaultProps = {
  open: false,
  onNextClick: null,
  onPrevClick: null,
  small: false,
  closeButton: true,
  isOdysseyUrl: false,
  screenType: 'lg',
  showPrev: true,
  showNext: true,
  obidoData: null,
  screenWidth: 0,
  isPositionDefault: false,
  isStaticHeight: false,
  titleLinesCount: 1,
  isMobileFullScreen: false,
};

export default withSizesUtil()(Modal);
