import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
  height: ${({ $mapHeight }) => ($mapHeight ? `${$mapHeight}px` : '100%')};

  .gm-style iframe + div {
    border: none !important;
  }

  .gm-bundled-control-on-bottom {
    width: 29px;
    bottom: ${({ isModal }) => (isModal ? '50% !important' : '63px !important')};
    right: 6px !important;

    & > div {
      border-radius: 8px;
      overflow: hidden;
      width: 29px !important;
      height: 57px !important;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

      & > div > div {
        width: 19px !important;
      }
    }

    .gm-control-active {
      border-radius: 8px;
      width: 29px !important;
      height: 28px !important;

      & > img {
        height: 10px !important;
        width: 10px !important;
      }
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    height: ${({ isModal, $isMobileMapBigger }) => {
    if (isModal) return '100%';
    return $isMobileMapBigger ? '410px' : '200px';
  }};

    .gmnoprint a, .gmnoprint span, .gm-style-cc {
      display:none;
    }
  }
`;

const StaticWrapper = styled.div`
  cursor: pointer;
  width: 100%;
  height: 100%;
  background-image: url(${({ $mapUrl }) => $mapUrl});
  background-position: center center;
  background-size: cover;
`;

const Empty = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DefaultZoomButton = styled.div`
  box-sizing: border-box;
  position: absolute;
  bottom: ${({ $activeMarkedEntityData, $isModalOpen }) => ($activeMarkedEntityData && $isModalOpen ? '291px' : '20px')};
  right: 20px;
  width: 30px;
  height: 30px;
  padding: 5px 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  border-radius: 8px;
  cursor: pointer;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
  color: #676767;
  font-size: 12px;
  font-weight: 900;
  font-family: Inter, sans-serif;
  transition: bottom 0.2s ease-out;

  @media (max-width: ${(props) => props.theme.breakpoints.sm}) {
    right: 16px;
  }
`;

export {
  Wrapper, StaticWrapper, Empty, DefaultZoomButton,
};
