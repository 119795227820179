import { withTheme } from 'styled-components';

import { isUndefined } from 'lodash';

import RCSlider from 'rc-slider';
import { FormattedNumber } from 'react-intl';
import { Wrapper, MinLegend, MaxLegend } from './Slider.styles';
import 'rc-slider/assets/index.css';

const Slider = ({
  minValue,
  maxValue,
  minSelected = null,
  maxSelected = null,
  handleChange = () => {},
  theme,
  isWithoutExtraPadding = false,
  formatDisplay = null,
}) => {
  const trackStyle = {
    marginLeft: '10px',
    backgroundColor: theme.primary3Color,
    height: '4px',
  };

  const railStyle = {
    borderRadius: '0',
    height: '4px',
    marginLeft: '5px',
  };

  const dotStyle = {
    outline: 'none',
    boxShadow: 'none',
  };

  const minFinalValue = isUndefined(minSelected) ? minValue : minSelected;
  const maxFinalValue = isUndefined(maxSelected) ? maxValue : maxSelected;

  return (
    <Wrapper $isWithoutExtraPadding={isWithoutExtraPadding}>
      <MinLegend>
        <FormattedNumber value={minFinalValue} maximumFractionDigits={0} />
        &nbsp;{formatDisplay}
      </MinLegend>
      <RCSlider
        range
        min={minValue}
        max={maxValue}
        trackStyle={[trackStyle]}
        handleStyle={[dotStyle, dotStyle]}
        railStyle={railStyle}
        allowCross={false}
        value={[minFinalValue, maxFinalValue]}
        onChange={handleChange}
      />
      <MaxLegend>
        <FormattedNumber value={maxFinalValue} maximumFractionDigits={0} />
        &nbsp;{formatDisplay}
      </MaxLegend>
    </Wrapper>
  );
};

export default withTheme(Slider);
