const POIGastronomyIcon = (props = {}) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='18'
    height='15'
    viewBox='0 0 18 15'
    {...props}
  >
    <path
      className='cls-1'
      d='M1317.32,1740.41h11.36V1743h-11.36v-2.59Zm11.36-10.62A3.342,3.342,0,0,1,1323,1728a3.342,3.342,0,0,1-5.68,1.79,3.256,3.256,0,1,0,0,6.51v2.96h11.36v-2.96A3.256,3.256,0,1,0,1328.68,1729.79Z'
      transform='translate(-1314 -1728)'
    />
  </svg>
);
export default POIGastronomyIcon;
