import styled from 'styled-components';
import { ContainerMain } from 'utils/styles';

const FixedWrapper = styled.div`
  position: fixed;
  z-index: 101;
  width: 100%;
  background-color: ${(props) => props.theme.primary2Color};
  box-shadow: ${(props) =>
    props.$isDesktopStickyBarShown
      ? 'none'
      : `0 5px 20px -10px rgba(${props.theme.shadowColor})`};
`;

const HeaderPlaceholder = styled.div`
  height: 88px;
`;

const Wrapper = styled(ContainerMain)`
  height: 88px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding-top: 0 !important;
`;

const LogoWrapper = styled.div`
  box-sizing: border-box;
  max-width: 318px;
  min-width: 160px;
  max-height: 100%;
  height: 100%;
`;

const MenuWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;

const SocialLinksWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  right: 0;
  transform: translate(0, 25%);
  height: 100%;
`;

export {
  FixedWrapper,
  HeaderPlaceholder,
  Wrapper,
  LogoWrapper,
  MenuWrapper,
  SocialLinksWrapper,
};
