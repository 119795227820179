import PropTypes from 'prop-types';

import POICulture from 'components/icons/POICulture';
import POIGastronomy from 'components/icons/POIGastronomy';
import POIGym from 'components/icons/POIGym';
import POIHealth from 'components/icons/POIHealth';
import POIPost from 'components/icons/POIPost';
import POIRecreation from 'components/icons/POIRecreation';
import POISchool from 'components/icons/POISchool';
import POIService from 'components/icons/POIService';
import POIShop from 'components/icons/POIShop';
import OfficeMarker from 'components/icons/OfficeMarker';
import InvestmentMarker from 'components/icons/InvestmentMarker';

import {
  PoiTooltip,
  InvestmentMarkerWrapper,
  InvestmentTooltip,
  OfficeMarkerWrapper,
  OfficeTooltip,
  MarkerWrapper,
} from './MapMarkerFactory.styles';

const definedPoITypes = [
  'POIGastronomy',
  'POIGym',
  'POIRecreation',
  'POISchool',
  'POIService',
  'POIShop',
];

const OFFICE = 'Office';
const INVESTMENT = 'Investment';

const getIcon = (point) => {
  switch (point.type) {
    case 'POICulture':
      return <POICulture />;
    case 'POIGastronomy':
      return <POIGastronomy />;
    case 'POIGym':
      return <POIGym />;
    case 'POIHealth':
      return <POIHealth />;
    case 'POIPost':
      return <POIPost />;
    case 'POIRecreation':
      return <POIRecreation />;
    case 'POISchool':
      return <POISchool />;
    case 'POIOther':
      return <POIService />;
    case 'POIShop':
      return <POIShop />;
    case OFFICE:
      return point.isOldMarkerStyle ? <OfficeMarker /> : <InvestmentMarker />;
    case INVESTMENT:
      return <InvestmentMarker />;
    default:
      return null;
  }
};

const getTooltip = (
  { name, checked, hideTooltip, disabled, highlighted },
  type,
  isOldMarkerStyle
) => {
  switch (type) {
    case OFFICE:
      return (
        <OfficeTooltip disabled={disabled} $isOldMarkerStyle={isOldMarkerStyle}>
          {name}
        </OfficeTooltip>
      );
    case INVESTMENT:
      return (
        <InvestmentTooltip
          disabled={disabled}
          $hideTooltip={hideTooltip}
          $highlighted={highlighted}
          checked={checked}
          $isOldMarkerStyle={isOldMarkerStyle}
        >
          {name}
        </InvestmentTooltip>
      );
    default:
      return <PoiTooltip hideTooltip={hideTooltip}>{name}</PoiTooltip>;
  }
};

export const MapMarkerFactory = (props) => {
  const icon = getIcon(props);
  const hideTooltipIfUnchecked =
    !props.isChecked && props.hideTooltipIfUnchecked;
  const disabled = props.disabled;

  if (props.type === INVESTMENT) {
    return (
      <InvestmentMarkerWrapper
        onClick={() => {
          if (typeof props.onClick === 'function') {
            props.onClick({
              id: props.investmentId,
              slug: props.investmentSlug,
              type: props.type,
              route: props.route,
              externalURL: props.externalURL,
              name: props.name,
            });
          }
        }}
        checked={props.isChecked}
        disabled={disabled}
        $highlighted={props.highlighted}
        $isOldMarkerStyle={props.isOldMarkerStyle}
        $isTooltipOnHover={props.isTooltipOnHover}
        $isModalOpen={props.isModalOpen}
        $activeMarkedEntityData={props.activeMarkedEntityData}
      >
        {hideTooltipIfUnchecked || props.isModalOpen
          ? null
          : getTooltip(
              {
                highlighted: props.highlighted,
                disabled: props.disabled,
                name: props.name,
                hideTooltip: props.name === '',
                checked: props.isChecked,
              },
              props.type,
              props.isOldMarkerStyle
            )}
        {icon}
      </InvestmentMarkerWrapper>
    );
  }

  if (props.type === OFFICE) {
    return (
      <OfficeMarkerWrapper
        onClick={() => {
          if (typeof props.onClick === 'function') {
            props.onClick({
              id: props.investmentId,
              slug: props.investmentSlug,
              type: props.type,
              route: props.route,
              externalURL: props.externalURL,
              name: props.name,
            });
          }
        }}
        checked={props.isChecked}
        disabled={disabled}
        $isOldMarkerStyle={props.isOldMarkerStyle}
        $isTooltipOnHover={props.isTooltipOnHover}
        $isModalOpen={props.isModalOpen}
        $activeMarkedEntityData={props.activeMarkedEntityData}
      >
        {hideTooltipIfUnchecked || props.isModalOpen
          ? null
          : getTooltip(
              {
                highlighted: props.highlighted,
                disabled: props.disabled,
                name: props.name,
                hideTooltip: props.name === '',
                checked: props.isChecked,
              },
              props.type,
              props.isOldMarkerStyle
            )}
        {icon}
      </OfficeMarkerWrapper>
    );
  }

  return (
    <MarkerWrapper
      isOldMarkerStyle={props.isOldMarkerStyle}
      innerRef={props.pointRef}
    >
      {getTooltip(
        {
          name: props.name,
          hideTooltip:
            definedPoITypes.indexOf(props.type) === -1 || props.name === '',
        },
        undefined,
        props.isOldMarkerStyle
      )}
      {icon}
    </MarkerWrapper>
  );
};

MapMarkerFactory.propTypes = {
  onClick: PropTypes.func.isRequired,
  activeMarkedEntityData: PropTypes.object,
  disabled: PropTypes.bool,
  externalURL: PropTypes.string,
  hideTooltipIfUnchecked: PropTypes.bool,
  highlighted: PropTypes.bool,
  investmentId: PropTypes.number,
  investmentSlug: PropTypes.string,
  isChecked: PropTypes.bool,
  isModalOpen: PropTypes.bool,
  isOldMarkerStyle: PropTypes.bool,
  isTooltipOnHover: PropTypes.bool,
  name: PropTypes.string,
  pointRef: PropTypes.func,
  route: PropTypes.string,
  type: PropTypes.string,
};

MapMarkerFactory.defaultProps = {
  activeMarkedEntityData: null,
  name: undefined,
  type: undefined,
  externalURL: undefined,
  route: undefined,
  highlighted: false,
  investmentId: 0,
  investmentSlug: '',
  isChecked: false,
  disabled: false,
  hideTooltipIfUnchecked: false,
  isOldMarkerStyle: false,
  pointRef: () => {},
  isTooltipOnHover: false,
  isModalOpen: false,
};

export default MapMarkerFactory;
