const POIServiceIcon = (props = {}) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='16.03'
    height='16'
    viewBox='0 0 16.03 16'
    {...props}
  >
    <path
      d='M1034.95,1734.11a0.528,0.528,0,0,0-.51-0.39,1.769,1.769,0,0,1-1.21-3.08,0.435,0.435,0,0,0,.04-0.6,7.636,7.636,0,0,0-1.26-1.28,0.439,0.439,0,0,0-.61.05,1.858,1.858,0,0,1-2,.45,1.8,1.8,0,0,1-1.08-1.74,0.435,0.435,0,0,0-.39-0.47,7.741,7.741,0,0,0-1.8,0,0.434,0.434,0,0,0-.39.46,1.789,1.789,0,0,1-1.1,1.7,1.834,1.834,0,0,1-1.98-.45,0.447,0.447,0,0,0-.6-0.05,8.287,8.287,0,0,0-1.3,1.28,0.45,0.45,0,0,0,.05.61,1.76,1.76,0,0,1,.45,1.99,1.857,1.857,0,0,1-1.74,1.09,0.437,0.437,0,0,0-.47.39,7.913,7.913,0,0,0,0,1.82,0.543,0.543,0,0,0,.53.39,1.757,1.757,0,0,1,1.64,1.1,1.781,1.781,0,0,1-.45,1.98,0.435,0.435,0,0,0-.04.6,8.026,8.026,0,0,0,1.26,1.28,0.44,0.44,0,0,0,.61-0.05,1.846,1.846,0,0,1,1.99-.45,1.79,1.79,0,0,1,1.09,1.74,0.448,0.448,0,0,0,.39.47c0.3,0.03.61,0.05,0.92,0.05a8.652,8.652,0,0,0,.88-0.05,0.434,0.434,0,0,0,.39-0.46,1.789,1.789,0,0,1,1.1-1.7,1.83,1.83,0,0,1,1.98.45,0.447,0.447,0,0,0,.6.05,8.287,8.287,0,0,0,1.3-1.28,0.45,0.45,0,0,0-.05-0.61,1.772,1.772,0,0,1-.45-2,1.8,1.8,0,0,1,1.64-1.08h0.1a0.448,0.448,0,0,0,.47-0.39A7.913,7.913,0,0,0,1034.95,1734.11Zm-7.94,3.58a2.67,2.67,0,1,1,2.67-2.67A2.676,2.676,0,0,1,1027.01,1737.69Z'
      transform='translate(-1019 -1727)'
    />
  </svg>
);
export default POIServiceIcon;
