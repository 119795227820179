import { cloneElement } from 'react';
import { withTheme } from 'styled-components';

const Link = ({
  icon,
  href,
  darkColor = false,
  smallIcon = false,
  theme,
  tracked = false,
}) => (
  <a
    href={href}
    target='_blank'
    rel='noopener noreferrer'
    className={tracked ? 'yl-link' : ''}
  >
    {cloneElement(icon, {
      fill: darkColor ? theme.primary3Color : '#e5e8e9',
      height: smallIcon ? '18px' : '27px',
    })}
  </a>
);

export default withTheme(Link);
