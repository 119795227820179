import Link from 'components/ui/Link';

import { HoverUnderline, Wrapper } from './HorizontalMenuLink.styles';

const HorizontalMenuLink = ({
  active = true,
  children,
  hovered = false,
  href = null,
  route = null,
  params = {},
  scroll = true,
  trackPage = () => {},
}) => (
  <Link
    href={href || route}
    params={params}
    active={active}
    scroll={scroll}
    onClick={() => trackPage(route)}
  >
    <Wrapper active={active}>
      {children}
      {hovered ? <HoverUnderline /> : null}
    </Wrapper>
  </Link>
);

export default HorizontalMenuLink;
